import React, { useState } from 'react';
import {FaCamera, FaImage} from "react-icons/fa";
import posthog from 'posthog-js';


function PhotoUploadComponent(props) {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    posthog.capture('photo upload', { topic: props.topic, sessionId: props.sessionUUID})
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      setFile(uploadedFile);
      props.onPhotoSelect(uploadedFile); // Callback to send the file to the API
    }
  };

  return (
      <div className="">
        <label htmlFor="file-upload" className="cursor-pointer">
          <FaImage size={18} className=" text-zinc-400" aria-hidden="true" />
        </label>
        <input
          id="file-upload"
          type="file"
          className="sr-only"
          accept="image/*"
          onChange={handleFileChange}

        />
      </div>

  );
}

export default PhotoUploadComponent;
