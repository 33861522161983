import { useState, useEffect } from 'react';
import { Progress, Loader } from '@mantine/core';
import { FaCheckCircle } from 'react-icons/fa';
import { ChevronsUpDown } from "lucide-react"
import { IoSparklesSharp } from "react-icons/io5";
import { ProcessSectionCard } from './ProcessSectionCard';
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
  } from "../@/components/ui/ProcessSectionAccordion"
import {
    Collapsible,
    CollapsibleContent,
    CollapsibleTrigger,
} from "../@/components/ui/collapsible"
import { ScrollArea, ScrollBar } from "../@/components/ui/scroll-area"
import posthog from 'posthog-js';
import PhotoMini from './photos/photoMini';


export const ProcessSectionProgress = ({progressMessages, loaderVisible, photo}) => {

    const [values, setValues] = useState("")
    const [collapsibleOpen, setCollapsibleOpen] = useState(false);

    //on prop change, set the values to the length of the progressMessages
    useEffect(() => {
        if (progressMessages.length == 0) {setValues("")}
        else {setValues((progressMessages.length -1).toString())}

    }, [progressMessages]);


    return (
        <>
        <div className="w-full">
            <div className='w-full'>
            <Progress radius="xl" size="md" color="#4338ca"
                value={progressMessages.length == 0 ? "0" : progressMessages[progressMessages.length-1].value}
                className="my-4" />

            <Collapsible className={`rounded-xl w-full border-borderMain ${!collapsibleOpen ? "hover:text-indigo-700 w-full" : ""}`} open={collapsibleOpen} onOpenChange={setCollapsibleOpen}>
                    <CollapsibleTrigger className=" text-base justify-between  p-4  cursor-pointer w-full">
                        <div className="flex justify-between items-center w-full">
                            <div className="flex items-center tracking-normal font-sans">
                                <>
                                    <Loader variant="dots" color="#4338ca" className='mr-2'/>
                                    <span className="tracking-normal font-sans"> {progressMessages[progressMessages.length-1].message} </span>
                                </>
                            </div>
                            <ChevronsUpDown />
                        </div>
                        <div className="overflow-x-auto">
                            <div className="flex w-max py-4 space-x-4">
                                {progressMessages[progressMessages.length-1].results?.filter((result, index, self) =>
                                        index === self.findIndex((t) => (
                                            t.title === result.title
                                        ))
                                    )
                                    .map((result, index) => (
                                        <ProcessSectionCard index={index} data={result} />
                                    ))}
                            </div>
                        </div>
                    </CollapsibleTrigger>
                    <CollapsibleContent className=" justify-between hover:text-bluelogo text-textGray p-4  cursor-pointer w-full">
                        <Accordion type="single" className="w-full">
                            {progressMessages?.map((messageObj, index) => (
                                <AccordionItem value={`${index}`} style={index === progressMessages.length - 1 ? { borderBottom: 'none' } : {}}>
                                    <AccordionTrigger disabled={messageObj.results.length == 0} className='text-textGray' onClick={() => {posthog.capture('Process Section Accordion Clicked', { messageObj })}}>
                                        <div className='flex items-center text-sm text-start'>
                                            <FaCheckCircle className='text-bluelogo transition-all duration-500 ease-in-out mr-2' size={15}/>
                                            <span className="tracking-normal font-sans">{messageObj.message}</span>
                                        </div>
                                    </AccordionTrigger>
                                    <AccordionContent >
                                            <div className="overflow-x-auto">
                                            <div className="flex w-max space-x-4 p-4">
                                                    {messageObj.results
                                                    .filter((result, index, self) =>
                                                        index === self.findIndex((t) => (
                                                            t.title === result.title
                                                        ))
                                                    )
                                                    .map((result, index) => (
                                                        <ProcessSectionCard index={index} data={result} />
                                                    ))}
                                            </div>
                                            </div>
                                    </AccordionContent>
                                </AccordionItem>
                            ))}
                        </Accordion>
                    </CollapsibleContent>
                </Collapsible>
            {/* <Accordion type="single" className="w-full max-w-full"
                value={values}
                onValueChange={setValues}>
                {progressMessages.map((messageObj, index) => (
                    <AccordionItem value={index.toString()}>
                        <AccordionTrigger className={(index == progressMessages.length - 1) ? "text-textMain tracking-normal" : "text-textGray tracking-normal"}
                            disabled={messageObj.results.length == 0} onClick={() => {posthog.capture('Process Section Accordion Clicked', { messageObj })}} >
                            <div className='flex items-center'>
                                {(index == progressMessages.length - 1) ? (
                                    <>
                                    <Loader variant="dots" color="#4338ca" className='mr-2'/>
                                    <span className="tracking-normal font-sans"> {messageObj.message} </span>
                                    </>
                                ) : (
                                    <>
                                    <FaCheckCircle className='text-textGray transition-all duration-500 ease-in-out mr-2' size={15}/>
                                    <span className="tracking-normal font-sans"> {messageObj.message} </span>
                                    </>
                                )}
                            </div>
                        </AccordionTrigger>
                        <AccordionContent >
                                <div className="overflow-x-auto">
                                <div className="flex w-max p-4 space-x-4">
                                    {messageObj?.results.filter((result, index, self) =>
                                            index === self.findIndex((t) => (
                                                t.title === result.title
                                            ))
                                        )
                                        .map((result, index) => (
                                            <ProcessSectionCard index={index} data={result} />
                                        ))}
                                </div>
                                </div>
                        </AccordionContent>
                    </AccordionItem>
                ))}
            </Accordion> */}
            </div>
        </div>
        </>
    );
}