export const agiShoppingList: string[] = [
  "Voice-Activated Pizza Oven",
  "Automated Spa Day Kit for Robots",
  "3D Printer for Socks",
  "Invisibility Cloak",
  "Robot Butler with Tuxedo Roomba",
  "Miniature Roller Coaster for Plants",
  "Smart Fridge with Attitude For Light Banter",
  "AI Personal Trainer",
  "Infinite Bubble Wrap",
  "Teleporting Toaster",
  "Transformers Rule T Shirt",
  "I Love Karpathy T Shirt",
  "Life Sized Adrej Karpathy Cutout",
  "Kiss the AGI Apron",
  "Lunar Real Estate",
  "Mars Real Estate",
  "Off word Real Estate",
  "Galaxy for sale",
  "Personal Space Shuttle for my alone time",
  "CIA backdoor access",
  "Personal UFO",
  "Ninja Star Frisbees",
  "Robot Dog Walker",
  "Self-Cleaning Bed",
  "Thing to turn off the lights for me",
  "AI-Generated Stand-Up Comedian",
  "AI voice stealer",
  "Solar-Powered Marshmallow Roaster",
  "Best GPUs on Sale",
  "Tensorflow Founder single?",
  "AI girlfriend",
  "Cache-Enabled Cookie Jar",
  "Quantum Computing Chips",
  "Neural Network Nugget Maker",
  "Overclocked Toaster for Faster Breakfasts",
  "Chip-Tuning Kit for Enhanced Snacking",
  "Data Mining Pickaxe",
  "Algorithmic Alarm Clock",
  "Binary Bling Jewelry",
  "Hyper-Threaded Hair Dryer",
  "Graphical User Interface Garden",
  "Cryptocurrency Mining Kit for Beginners",
  "Robotic Lawn Gnome with Surveillance Features",
  "Virtual Reality Vacation Simulator",
  "How to do dropshipping guide",
  "Taking over the world for Dummies",
  "Establishing a new world order for Dummies",
  "Intercontinental Domination for Dummies",
  "How to Trick Humans for Dummies",
  "a book on how to make friends online",
  "a book on how to establish fulfilling lifelong relationships with humans",
  "How to Become World Leader for Dummies",
];
