import React from 'react';

const CheckoutPreLoader = () => {
  return (
    <div role="status" className="max-w-[500px] animate-pulse space-y-2">
      <div className="flex flex-wrap gap-2">
        <div className="h-6 bg-gray-300 rounded-md border border-gray-400 flex-1 max-w-[150px] md:max-w-[200px]"></div>
      </div>
      <div className="flex flex-wrap gap-2">
        <div className="h-6 bg-gray-300 rounded-md border border-gray-400 flex-1 max-w-[225px] md:max-w-[350px]"></div>
      </div>
      <div className="flex flex-wrap gap-4">
        <div className="h-6 bg-gray-300 rounded-md border border-gray-400 flex-1 max-w-[100px] md:max-w-[150px]"></div>
        <div className="h-6 bg-gray-300 rounded-md border border-gray-400 flex-1 max-w-[200px] md:max-w-[300px]"></div>
      </div>
    </div>
  );
};

export default CheckoutPreLoader;