import { Text, Paper, ActionIcon, Group } from '@mantine/core';
import { Card, Image, Badge, Button, Modal } from '@mantine/core';
import posthog from 'posthog-js';
import {useEffect, useState} from 'react';

import { formatHostname, extractHostname } from '../utils';
import {  Collapse, Box, MantineNumberSize } from '@mantine/core';
import { useDisclosure, useToggle } from '@mantine/hooks';
import styles from "../styles/Search.module.css";
import { ProductPreview } from '../ProductPreview';
import { PageContext } from '../../../contexts/pageContext';
import { useContext } from 'react';
import { merchantMonetizeAndSwap } from '../../utils/IonicCommerce';

const API_URL = process.env.NEXT_PUBLIC_API_URL;

const extractNumber = (str, pattern) => {
    const match = str.match(pattern);
    return match ? match[0] : null;
};

let numberPattern = /\d+(\.\d+)?|\.\d+/

// Function to handle the display of rating and review count
const displayRatingOrReview = (data) => {
    if (typeof data === 'string') {
        return extractNumber(data, numberPattern);
    } else if (typeof data === 'number') {
        return data.toLocaleString();
    } else {
        return null;
    }
};

export function ProductCardModal(props) {
    const [opened, { toggle }] = useDisclosure(false);
    const [productInfo, setProductInfo] = useState({
        title: props.title, sku: props.sku, images: [props.image], price: props.price, rating: props.rating, detailPageURL: props.detailPageURL, taggedURL: props.link, description: "", category: [], delivery: [], bulletPoints: []
    });
    const context = useContext(PageContext)
    const AFFILIATE_LINK = context.affiliateLink;

    useEffect(() => {
        posthog.init(process.env.NEXT_PUBLIC_POSTHOG_API_KEY, { api_host: 'https://app.posthog.com' });
    }, []);

    // Update productInfo when props change
    useEffect(() => {
        setProductInfo({
            title: props.title,
            sku: props.sku,
            images: [props.image],
            price: props.price,
            rating: props.rating,
            detailPageURL: props.detailPageURL,
            taggedURL: props.link,
            description: "",
            category: [],
            delivery: [],
            bulletPoints: []
        });
    }, [props.detailPageURL]);


    {/* log click and go to link */}
    async function handleClick(link) {
        posthog.capture('product click', {main: true, product: link, storeId: context.storeId})
        if (context.isMainPage) {
            toggle()
            const updatedProductInfo = await merchantMonetizeAndSwap(productInfo, AFFILIATE_LINK, props.searchQuery);
            setProductInfo(updatedProductInfo);
        } else {
            window.open(link, "_blank");
        }
    }


        return (
                <>
                {opened && (
                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto" >
                    <ProductPreview
                        opened={opened}
                        toggle={toggle}
                        title={props.title}
                        price={props.price}
                        image={props.image}
                        link={productInfo.taggedURL}
                        detailPageURL={props.detailPageURL}
                        rating={props.rating}
                        sku={props.sku}
                        description={productInfo.description}
                        category={productInfo.category}
                        delivery={productInfo.delivery}
                        bulletPoints={productInfo.bulletPoints}
                        aiSummary={props.aiSummary}
                        key={props.index}
                        handleProductSpecificSearch={props.handleProductSpecificSearch}
                        session={props.session}
                        forceCheckout={props.forceCheckout}
                        productInfo={productInfo}
                        setCheckoutProduct={props.setCheckoutProduct}
                        redditRefs={props.redditRefs}
                        videos={props.videos}
                        gl={props.gl}
                        searchQuery={props.searchQuery}
                        askedQuestions={props.askedQuestions}
                    />
                    </div>
                )}
                <div className="mx-auto w-full overflow-visible min-w-[300px] px-2" id="product-card">
                    <div className="group relative cursor-pointer" onClick={() => handleClick(props.link)}  >
                        <div className={`rounded-xl shadow-none flex flex-row  tracking-wide leading-8 bg-white  hover:border-bluelogo transition-colors duration-200 ease-in-out border border-zinc-100 overflow-hidden`} >
                            <div className="bg-cover bg-no-repeat bg-center p-4 min-w-32 sm:w-44 md:w-96 lg:w-96">
                                <div style={{ backgroundImage: `url(${props.image})`}} className="mx-auto h-full rounded-xl bg-contain bg-no-repeat bg-center object-contain" />
                            </div>
                            <div className="p-3 w-full">
                                <h3 className="mt-4  text-base text-textMain line-clamp-3 sm:line-clamp-2 tracking-normal">
                                    {props.title}
                                </h3>



                                <h3 className="mt-4  text-sm text-textGray flex justify-between tracking-normal">

                                <div>
                                    {props.reviews_count && (
                                        `${displayRatingOrReview(props.reviews_count)} reviews`
                                    )}
                                </div>
                                <div>
                                    {props.rating && `★ ${displayRatingOrReview(props.rating)}`}
                                </div>
                                </h3>

                                <div className="flex justify-between">
                                    <div className='mt-4 text-base text-gray-600 pr-2 tracking-normal'>
                                        {props.price}
                                    </div>
                                    {context.isMainPage && (
                                        <div className='mt-4 text-sm text-textGray tracking-normal text-right'>
                                            { props.source ? formatHostname(props.source) :  extractHostname(props.detailPageURL)  }
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="absolute bottom-2 right-2">
                                    <div className="flex items-center">
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )


}

