import React, { useState, useRef, useEffect } from 'react';
import posthog from 'posthog-js';
import { useContext } from 'react';
import { PageContext } from '../contexts/pageContext';
import { FaLink, FaFacebookF, FaTwitter, FaLinkedinIn, FaWhatsapp } from 'react-icons/fa';
import { IoIosLink } from "react-icons/io";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "../@/components/ui/dropdown-menu"

const languageMap = {
    'English': {
        sharebutton: 'Share',
        copied: 'Copied!',
        copyLink: 'Copy link',
    },
    'en-IN': {
        sharebutton: 'Share',
        copied: 'Copied!',
        copyLink: 'Copy link',
    },
    'Spanish': {
        sharebutton: 'Compartir',
        copied: 'Copiado!',
        copyLink: 'Copiar link',
    },
}

export function ShareTopic(props: any) {
    const context = useContext(PageContext)
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const link = "https://claros.so/threads/" + props.sessionId

    const dropdownRef = useRef(null);

    const [copied, setCopied] = useState(false);

    const handleCopy = async () => {
        await navigator.clipboard.writeText(link);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    }


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setToggleDropdown(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);



    function handleClick(sessionId) {
        // posthog.capture('feedback clicked' , { 'feedback': answer,  storeId: context.storeId })
        // alert("Thank you for your feedback!")

        //copy the link to clipboard
        toggleDropdown ? setToggleDropdown(false) : setToggleDropdown(true)


        navigator.clipboard.writeText(link)

        setCopied(true);

        posthog.capture('share clicked' , { 'link': link,  storeId: context.storeId })
    }

    return (
        <>
        <DropdownMenu>
        <DropdownMenuTrigger asChild>
            <div className="flex justify-between py-4 text-lg md:text-xl lg:text-xl max-w-full">
                <div className="items-center flex  cursor-pointer pb-1 hover:text-textGray text-textMain line-clamp-1 tracking-normal" onClick={handleClick}>
                    {props.topic} 
                    {/* <IoIosLink className='ml-2' /> */}
                </div>
            </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className=" right-0 z-10 mt-1 bg-white rounded-xl shadow-xl rounded-xl border-2 border-mainBorder bg-gray-50 p-2 sm:p-3">
                    <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(link)}`} target="_blank" rel="noopener noreferrer" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                        <div className="flex items-center">
                            <div className="mr-2">
                                <FaFacebookF style={{ color: context.buttonColor }} />
                            </div>
                        Facebook
                    </div>
                    </a>
                    <a href={`https://twitter.com/share?url=${encodeURIComponent(link)}`} target="_blank" rel="noopener noreferrer" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                        <div className="flex items-center">
                            <FaTwitter style={{ color: context.buttonColor }} className="mr-2" />
                            Twitter
                        </div>
                    </a>
                    <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(link)}`} target="_blank" rel="noopener noreferrer" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                        <div className="flex items-center">
                            <FaLinkedinIn style={{ color: context.buttonColor }} className="mr-2" />
                            LinkedIn
                        </div>
                    </a>
                    <a href={`https://api.whatsapp.com/send?text=${encodeURIComponent(link)}`} target="_blank" rel="noopener noreferrer" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                        <div className="flex items-center">
                            <FaWhatsapp style={{ color: context.buttonColor }} className="mr-2" />
                            WhatsApp
                        </div>
                    </a>
                    <p onClick={(e) => {handleCopy();}} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" style={{ color: copied ? context.buttonColor : 'inherit' }}>
                        <div className="flex items-center">
                            <FaLink style={{ color: context.buttonColor }} className="mr-2" />
                            {copied ? languageMap[context.language].copied : languageMap[context.language].copyLink}
                        </div>
                    </p>
      </DropdownMenuContent>
    </DropdownMenu>
            {/* {toggleDropdown && (
                    //dropdown menu
                
            )} */}
    </>
    )
}
