import { Send, Refresh, Share, Clipboard } from 'tabler-icons-react';
import { Space, Group,  ActionIcon, } from '@mantine/core';
import React from 'react';
import { useForm } from '@mantine/form';
import posthog from 'posthog-js';
import { PageContext } from '../../contexts/pageContext';
import { useContext } from 'react';
import { useState,useEffect, useRef } from 'react';
import { BetterButton } from '../utils/Buttons';
import { ShareButton } from '../ShareButton';
import { ClassNames } from '@emotion/react';
import { ChatInput } from './ChatInput';
import { PiArrowUpBold } from "react-icons/pi";



export function ChatInputBar(props: any) {
    const context = useContext(PageContext)

    // useEffect(() => {

    // }, []);


    function handleChange(event: any) {
        const inputOptions = event.target.value;
        props.updateQuery(inputOptions);
    }



    function handleKeyDown(event: any) {
        if (event.key === 'Enter') {
            handleClick()
        }
    }

    function handleClick() {
        let questionsAnswers = [ ...props.askedQuestions, [['What else can I assist you with?', props.query]] ]
        props.setAskedQuestions(questionsAnswers);
        props.handleSubmit(props.query, props.qapair, props.topic, true, questionsAnswers);
        props.updateQuery("")
    }


    const iconButtonStyles = {
        awsBtn: {
            '--button-horizontal-padding': '0px', // Override the value here
            '--button-secondary-color-light': '00FFFFFF',
            fill:"none"

        },
        cancelButton: {
            '--button-horizontal-padding': '0px', // Override the value here
            '--button-secondary-color-light': '00FFFFFF',
            fill:"none",
            '--button-raise-level': '2px',

        }
    }

    return (
    // <div className='fixed grow flex space-x-2 rounded-xl mx-auto bottom-0 pb-4 w-[60%]' style={{ zIndex: 999, justifyContent: 'center' }}>

            <div className=' bg-white bottom-0 border-2 border-mainBorder flex space-y-2 rounded-xl px-2 mb-2 z-8 ' style={{
            justifyContent: 'center' }}>

        {/*<div className='bg-bgwhite border-2 border-blueoutline fixed bottom-[0px] flex space-y-2 rounded-xl pb-6 px-2 max-w-[700px] w-full mx-auto' style={{ zIndex: 999, justifyContent: 'center' }}> */}

            <div className='mx-0 flex-1'>
            <div className="flex space-x-2 rounded-xl text-sm sm:text-sm md:text-base " x-data="{ show: true }" x-clock>
                <input
                type="text"
                ref={props.inputRef} // Attach the ref to the input element
                value={props.query}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                placeholder="Ask me anything"
                className="grow bg-transparent text-gray-900 focus:outline-none  tracking-normal p-2 sm:p-3"
                />
                <div className="justify-end flex">

                    {/* <ShareButton sessionId={props.sessionUUID} isMobile={props.isMobile} />
                    hover-shadow flex w-40 select-none items-center justify-center rounded-full bg-indigo-700  py-2 text-sm text-white transition-all hover:bg-indigo-600 active:scale-95
                    */}

                    <button onClick={handleClick} className=" hover-shadow flex select-none items-center justify-center bg-indigo-600 text-sm text-white transition-all hover:bg-indigo-500 active:scale-95 float-right  rounded-xl cursor-point origin-center whitespace-nowrap font-medium  p-2 my-2">
                        <PiArrowUpBold
                            size={15}
                            color={'white'}/>
                    </button>
                </div>

            </div>
            {/* <ChatInput
                        query={props.query}
                        onOptionSelect={handleOptionSelect}
                        qapair={props.qapair}
                        topic={props.topic}
                        handleSubmit={props.handleSubmit}
                        options={props.options}
                        open={props.feedbackOpened}
                        setOpen={props.setFeedbackOpened}
                        isMobile={props.isMobile}
                        sessionUUID={props.sessionUUID}
                    /> */}
            </div>
        </div>
    )
}
