import { useState, useEffect } from 'react';
import { Progress, Loader } from '@mantine/core';
import { FaCheckCircle } from 'react-icons/fa';
import { ChevronsUpDown } from "lucide-react"
import { IoSparklesSharp } from "react-icons/io5";
import { ProcessSectionCard } from './ProcessSectionCard';
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
  } from "../@/components/ui/ProcessSectionAccordion"
import {
    Collapsible,
    CollapsibleContent,
    CollapsibleTrigger,
} from "../@/components/ui/collapsible"
import { ScrollArea, ScrollBar } from "../@/components/ui/scroll-area"
import posthog from 'posthog-js';
import PhotoMini from './photos/photoMini';

function extractInfo(value, progressMessages){
    let exctractedInfo = {}
    progressMessages.forEach((message, index) => {
        if (message.value == value){
            return exctractedInfo
        }
    })
}




export const ProcessSection = ({progressMessages, loaderVisible}) => {

    const [values, setValues] = useState("")
    const [collapsibleOpen, setCollapsibleOpen] = useState(false);

    //on prop change, set the values to the length of the progressMessages
    useEffect(() => {
        if (typeof window !== 'undefined' && window.location.hostname === 'localhost') {
            console.log('Progress Messages:', progressMessages);
        }

        if (progressMessages.length == 0) {setValues("")}
        else {setValues((progressMessages.length -1).toString())}
    }, [progressMessages]);


    return (
        <>
            <div className="w-full">
                <Collapsible className={`rounded-xl w-full border-2 border-borderMain ${!collapsibleOpen ? "hover:bg-cardBg w-full" : ""}`} open={collapsibleOpen} onOpenChange={setCollapsibleOpen}>
                    <CollapsibleTrigger className=" text-base justify-between text-textGray p-4  cursor-pointer w-full">
                        <div className="flex justify-between items-center w-full">
                            <div className="flex items-center tracking-normal font-sans">
                                <IoSparklesSharp className=' mr-2' size={20}/>
                                <div>{progressMessages?.length} steps done </div>
                            </div>
                            <div>
                                <ChevronsUpDown />
                            </div>
                        </div>
                    </CollapsibleTrigger>
                    <CollapsibleContent className=" justify-between hover:text-bluelogo text-textGray p-4  cursor-pointer w-full">
                        <Accordion type="single" className="w-full">
                            {progressMessages?.map((messageObj, index) => (
                                <AccordionItem value={`${index}`} style={index === progressMessages.length - 1 ? { borderBottom: 'none' } : {}}>
                                    <AccordionTrigger disabled={messageObj.results.length == 0} className='text-textGray' onClick={() => {posthog.capture('Process Section Accordion Clicked', { messageObj })}}>
                                        <div className='flex items-center text-sm text-start'>
                                            <FaCheckCircle className='text-bluelogo transition-all duration-500 ease-in-out mr-2' size={15}/>
                                            <span className="tracking-normal font-sans">{messageObj.message}</span>
                                        </div>
                                    </AccordionTrigger>
                                    <AccordionContent >
                                            <div className="overflow-x-auto">
                                            <div className="flex w-max space-x-4 p-4">
                                                    {messageObj.results
                                                    .filter((result, index, self) =>
                                                        index === self.findIndex((t) => (
                                                            t.title === result.title
                                                        ))
                                                    )
                                                    .map((result, index) => (
                                                        <ProcessSectionCard index={index} data={result} />
                                                    ))}
                                            </div>
                                            </div>
                                    </AccordionContent>
                                </AccordionItem>
                            ))}
                        </Accordion>
                    </CollapsibleContent>
                </Collapsible>
            </div>
        </>
    );
}