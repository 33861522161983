import React, { useEffect } from 'react'
import { FaCheckCircle } from 'react-icons/fa'
import { IoSparklesSharp } from 'react-icons/io5'
import { Progress } from '@mantine/core'
import { FaExternalLinkAlt } from "react-icons/fa";
import clsx from 'clsx';
import { getMoreIdeas } from './headerCalls';
import { IoMdRefresh } from "react-icons/io";
import { Loader } from '@mantine/core';
import posthog from 'posthog-js';


export const MoreIdeasCard = ({topic, ideas, setIdeas}) => {

    const [selected, setSelected] = React.useState(false)
    const [shown, setShown] = React.useState(true)
    const [loading, setLoading] = React.useState(false)
    async function onCardClick() {
        posthog.capture('more ideas clicked' , {topic: topic, ideas: ideas})
        setLoading(true)
        let moreIdeas = await getMoreIdeas(topic, ideas);
        // Remove duplicates based on name.lower
        const filteredMoreIdeas = moreIdeas.filter((newIdea) =>
            !ideas.some((existingIdea) => existingIdea.name.toLowerCase() === newIdea.name.toLowerCase())
        );
        setIdeas([...ideas, ...filteredMoreIdeas])
        setLoading(false)

        // const combinedIdeas = [...ideas, ...moreIdeas];
        // const uniqueIdeas = combinedIdeas.reduce((acc, current) => {
        //     const x = acc.find(item => item.name.toLowerCase() === current.name.toLowerCase());
        //     if (!x) {
        //         return acc.concat([current]);
        //     } else {
        //         return acc;
        //     }
        // }, []);
    }

    return (
        <>
        {shown && (
        <div className={clsx("rounded-full mt-2 px-[10px] py-[4px] text-[13px] md:text-[12px] text-textGray ml-4 inline-block hover:bg-zinc-300 cursor-pointer active:scale-95",
        {"bg-zinc-300": selected},
        {"bg-cardBg": !selected})}
        onClick={onCardClick}>
            <div className="flex items-center justify-start">
                {loading ?
                        <Loader variant="dots" size={25} className='m-2' color='#3358d4' />
                    : (
                        <>
                        <IoMdRefresh className='mr-1' />
                        <span>More Ideas</span>
                        </>
                    )
                }
            </div>
        </div>
    )}
    </>
    )
}

