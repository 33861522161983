import React, { useEffect, useState } from 'react';
import { Loader } from '@mantine/core';
import clsx from 'clsx';
import posthog from 'posthog-js';

export const IdeaCard = ({ name, data, topic, setTopic, ideas, setIdeas }) => {
  const [selected, setSelected] = useState(data.selected);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSelected(data.selected);
  }, [data]);

  async function onCardClick() {
    posthog.capture('idea clicked', { topic, data, name, ideas });
    setLoading(true);

    let updatedIdeas = ideas.map((idea) => {
      if (idea.name === name) {
        let updatedOptions = idea.options.map((option) => {
          if (option.option === data.option) {
            option.selected = !option.selected;
            setSelected(option.selected); // Update local selected state
          }
          return option;
        });
        return { ...idea, options: updatedOptions };
      }
      return idea;
    });

    setIdeas(updatedIdeas);
    setLoading(false);
  }

  return (
    <div
      className={clsx(
        "rounded-full mt-2 px-3 py-1 text-[13px] md:text-[12px] ml-4 cursor-pointer text-center flex items-center justify-center leading-4 active:scale-95",
        { "bg-indigo-500 text-white hover:bg-indigo-400": selected },
        { "bg-cardBg text-textGray hover:bg-zinc-200": !selected }
      )}
      onClick={onCardClick}
    >
      {loading ? <Loader variant="dots" size={20} className="m-2" color="#3358d4" /> : data.option}
    </div>
  );
};
