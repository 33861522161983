import { MoreProductsModalContent} from "./MoreProductsModalContent"
import { useState } from "react"

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../@/components/ui/dialog"
import posthog from "posthog-js"

export function MoreProductsModal(props) {
  const [open, setOpen] = useState(false)
  return (
        <div className="flex items-center min-h-full mx-auto justify-end">
          <div className="hover:bg-zinc-200 cursor-pointer flex text-zinc-600 items-center text-sm bg-zinc-100 rounded-xl px-2 py-1 my-2" onClick={() => {console.log("click"); setOpen(true)}}>
            Show all {props.products.length} products considered
          </div>
          <MoreProductsModalContent
          open={open}
          toggle={() => {setOpen(!open); posthog.capture('show_all_products_clicked', {})}}
          products={props.products}
          setCheckoutProduct={props.setCheckoutProduct}
          forceCheckout={props.forceCheckout}
          session={props.session}
          className='px-2'
          redditRefs={props.redditRefs}
          videos={props.videos}
          gl={props.gl}
          searchQuery={props.searchQuery}
          askedQuestions={props.askedQuestions}
          handleProductSpecificSearch={props.handleProductSpecificSearch}
          />
        </div>
  )
}
