import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import posthog from 'posthog-js';

export const QuestionInset = ({ questionIndex, questionObj, answer, onAnswerChange, handleSubmitAll }) => {
  // Assuming answer is now an array to accommodate multiple selections
  const [localAnswers, setLocalAnswers] = useState(answer || '');
  const [optionStates, setOptionStates] = useState(questionObj.options.map(option => ({ option, selected: false })));
  const inputRef = useRef(null);

  // Update optionStates when questionObj changes
  useEffect(() => {
    setOptionStates(questionObj.options.map(option => ({ option, selected: false })));
  }, [questionObj]);

  // Update local state and parent state on option click
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      posthog.capture('follow-up other option submitted', {question: questionObj.question, answer: localAnswers});
      handleSubmitAll()
    }
  };
  function handleOptionClick(clickedOption) {
    const updatedOptionStates = optionStates.map(optionState => {
      if (optionState.option === clickedOption) {
        return { ...optionState, selected: !optionState.selected };
      }
      return optionState;
    });
    setOptionStates(updatedOptionStates);

    const optionState = updatedOptionStates.find(optionState => optionState.option === clickedOption);
    const optionSelected = optionState ? optionState.selected : false;
    let updatedAnswers = localAnswers;
    if (optionSelected) {
      updatedAnswers += `${updatedAnswers ? ', ' : ''}${clickedOption}`;
    } else {
      updatedAnswers = updatedAnswers.replace(new RegExp(`, ${clickedOption}|${clickedOption}, |${clickedOption}`, 'g'), '').trim();
    }
    setLocalAnswers(updatedAnswers);
    onAnswerChange(questionIndex, updatedAnswers);
  }
  // Highlight selected options
  function isOptionSelected(option) {
    return localAnswers.includes(option);
  }

  // Handle changes in the input field for typed answers
  function handleChange(event) {
    const newAnswer = event.target.value
    setLocalAnswers(newAnswer);
    onAnswerChange(questionIndex, newAnswer);
  }

  return (
    <div className="my-4 overflow-hidden">
      <div className="md:flex">
        <div className="w-full">
          <div className="mb-4 flex flex-wrap justify-start gap-2 items-center">
            {questionObj.options && questionObj.options.map((option, idx) => (
              <button
                key={idx}
                onClick={() => handleOptionClick(option)}
                className={clsx("font-sans active:scale-95 py-2 px-4 rounded-full text-xs font-medium focus:outline-none text-textGray tracking-normal",
                {"bg-indigo-500 text-white hover:bg-indigo-400": isOptionSelected(option)}, {"bg-cardBg text-textGray hover:bg-zinc-200": !isOptionSelected(option)})}
              >
                {option}
              </button>
            ))}
            <div className='w-full float-left bg-white border-2 border-mainBorder flex py-2 rounded-xl px-4 sm:max-w-12 md:max-w-64 tracking-normal'>
            <input
              type="text"
              ref={inputRef}
              value={localAnswers}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              placeholder="Other"
              className="w-full bg-transparent text-gray-700 placeholder-gray-400 focus:outline-none tracking-normal"
            />
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

