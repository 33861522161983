import React from 'react';


const PhotoMini = ({ photo }) => {

  if (!photo) return null;

  return (
    <div className="flex justify-center mt-4">
      <div className="relative w-24 h-24 overflow-hidden rounded-lg">
        <img
          src={URL.createObjectURL(photo)}
          alt="Uploaded"
          className="absolute top-0 left-0 w-full h-full object-cover rounded-lg"
        />
      </div>
    </div>
  );
};

export default PhotoMini;
