import React from 'react';

const makeTitleCase = (str) => {
    return str.replace(/\b\w/g, l => l.toUpperCase());
}

export const LabelBadgeMain = ({ label }) => {
    if (label) {
        return (
            <div className="absolute rounded-md bg-indigo-500 m-2">
                <div className='px-2 py-1 text-base text-zinc-100 font-bold'>
                {makeTitleCase(label)}
                </div>
            </div>
        );
    }
    return null;
};


export const LabelBadge = ({ label }) => {
    if (label) {
        return (
            <div className="absolute rounded-md bg-indigo-500 m-2">
                <div className='px-2 py-1 text-base text-zinc-100 font-bold'>
                {makeTitleCase(label)}
                </div>
            </div>
        );
    }
    return null;
};
