import { Text, Paper, ActionIcon, Group } from '@mantine/core';
import { Card, Image, Badge, Button, Modal } from '@mantine/core';
import posthog from 'posthog-js';
import {useEffect, useState} from 'react';
import { Center, Loader } from '@mantine/core';
import {  Collapse, Box, MantineNumberSize } from '@mantine/core';
import { useDisclosure, useToggle } from '@mantine/hooks';
import styles from "../styles/Search.module.css";

import { PageContext } from '../../contexts/pageContext';
import { useContext } from 'react';
import { AwesomeButton } from 'react-awesome-button';
import { IoCloseSharp } from "react-icons/io5";
import getSymbolFromCurrency from 'currency-symbol-map'
import { ConfirmCheckout } from '../checkout/ConfirmCheckout';
import CheckoutPreLoader from './CheckoutPreLoader';

export function CheckoutModal(props) {
  const context = useContext(PageContext)
  const handleIframeMessage = (event) => {
      //posts stripe result back to us
      if (event.data.checkout_token) {
          // console.log("updated checkout", event.data)
          props.setUpdatedCheckout(event.data);
          //alwasy close the iframe after submit data
          props.setCheckout(null)
      }
  };




  useEffect(() => {
      window.addEventListener('message', handleIframeMessage);
      return () => {
        window.removeEventListener('message', handleIframeMessage);
      };
  }, [props.message])



    function handleClick(link) {
      posthog.capture('product url click', { product: link, storeId: context.storeId})
      window.open(link, '_blank')
    }

    // console.log(props)

    function handleOverlayClick(event) {
      if (props.opened && !event.target.closest('#checkout-modal')) {
          props.toggle();
      }
    }

        return (
                <>
                <style>
                  {`
                    .product-preview {
                      position: absolute;
                      left: 0;
                      right: 0;
                      z-index: 9999;
                    }
                  `}
                </style>
                <div className="flex min-h-full  items-end justify-center p-4 text-center sm:items-center sm:p-0 product-preview overflow-y-scroll" style={{zIndex: 9999, background: 'rgba(0, 0, 0, 0.5)'}} hidden={!props.opened} onClick={handleOverlayClick}  >
                      <div id="checkout-modal" className="relative transform overflow-y-scroll rounded-xl text-left shadow-xl transition-all sm:my-8 mt-20 sm:w-full sm:max-w-lg">
                        <div className="absolute top-0 right-0">
                            <button className="flex items-center p-4 z-[10000]" onClick={() => {props.toggle()}}>
                              <IoCloseSharp className="h-6 w-6 text-gray-500" />
                            </button>
                        </div>
                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 overflow-y-scroll">

                        <div className="bg-contain bg-no-repeat bg-center p-8 mt-6 h-56 sm:h-60 md:h-60 lg:h-60" style={{ backgroundImage: `url(${props.checkoutProduct.images[0]})` }}>

                          </div>
                          {props.checkoutMessage &&
                            <div className='my-2 min-w-[350px]'>
                                <h3 className="text-md flex items-center"> {props.checkoutMessage} <Loader color="#4338CA" type="dots" size={20} className="ml-2" /></h3>
                                <div className='mt-4'>
                                <CheckoutPreLoader />
                                </div>
                            </div>
                          }

                          { props.checkout &&
                            <>
                                <div className="p-4 mt-4 rounded-md border-[1px] border-bluelogo">
                                    <div className='flex justify-between items-center'>
                                    <h3 className="text-lg font-medium">{props.checkout.items[0].name}</h3>
                                    <img  src={`https://www.google.com/s2/favicons?domain=${props.checkout.items[0].url}&sz=64`} alt="favicon" className='h-8 ml-2 ' />
                                    </div>
                                    <div className="mt-2">
                                        <div className="flex justify-between">
                                          <span className='font-medium'> Merchant </span>
                                          <span className=''> {props.checkout.merchant} </span>
                                          </div>
                                        <div className="flex justify-between">
                                          <span className='font-medium'>Subtotal</span>
                                          <span className=''>{getSymbolFromCurrency(props.checkout.currency)}{(props.checkout.subtotal_amount / 100).toFixed(2)}</span>
                                        </div>
                                        <div className="flex justify-between">
                                            <span className='font-medium'>Tax</span>
                                            <span className=''>{getSymbolFromCurrency(props.checkout.currency)}{(props.checkout.tax_amount / 100).toFixed(2)}</span>
                                        </div>
                                        <div className="flex justify-between">
                                            <span className='font-medium'>Total</span>
                                            <span className='font-normal'>{getSymbolFromCurrency(props.checkout.currency)}{((props.checkout.total_amount) / 100).toFixed(2)}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <iframe src={props.checkout.payment_form_url} width="100%" height="600px"></iframe>
                                </div>
                            </>
                          }

                        { props.updatedCheckout &&
                              <ConfirmCheckout updatedCheckout={props.updatedCheckout} confirmCheckout={props.confirmCheckout} checkoutSuccess={props.checkoutSuccess} setConfirmCheckout={props.setConfirmCheckout} />
                        }
                        </div>
                      </div>
                </div>
                </>
        )
}

