import {
  Space,
  Paper,
  Group,
  Button,
  Title,
  Text,
  TextInput,
  ActionIcon,
  Container,
  Grid,
} from "@mantine/core";
import { TrendingUp } from "tabler-icons-react";
import React, { useState, useContext } from "react";
import { PageContext } from "../../contexts/pageContext";
import { AwesomeButton } from "react-awesome-button";
import { BetterButton } from "../utils/Buttons";
import { LocationInput } from "../LocationInput";
import { useEffect, useRef } from "react";
import "react-awesome-button/dist/styles.css";
import { FaArrowRightLong } from "react-icons/fa6";
import { supabase_public } from "../../lib/supabaseClient";
import styles from "../styles/Search.module.css";
import { LuSendHorizonal } from "react-icons/lu";
import {FaSortAmountDown, FaInfoCircle} from "react-icons/fa";
import { HeaderIdeas } from "./HeaderIdeas";
import clsx from "clsx";
import { SearchIcon } from "lucide-react";
import { getIdeas, getPrice } from "./headerCalls";
import SkeletonIdeaComponent from "./SkeletonIdeaComponent";
import axios from "axios";
import posthog from 'posthog-js';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie'
const COOKIE_NAME_ID = 'product-help-chat-gpt3'
const COOKIE_NAME_COUNTRY = 'product-help-chat-gpt3-country'
import PhotoUploadComponent from "../photos/photoUpload";
import { HeaderCustomFilters } from "./HeaderCustomFilters";
import CustomFilters from "../profile/CustomFilters";
import { FaRobot } from "react-icons/fa";
import { agiShoppingList } from "./AGIBUY";
import TypeIt from "typeit-react";

const API_URL = process.env.NEXT_PUBLIC_API_URL;

export function HeaderInputMin(props: any) {
  const router = useRouter();
  const context = useContext(PageContext);

  const [isDisabled, setIsDisabled] = useState(props.topicSelected || false);
  const [recentProducts, setRecentProducts] = useState([]);
  const [signedIn, setSignedIn] = useState(false);
  const [ideas, setIdeas] = useState([]);
  const [savedIdeas, setSavedIdeas] = useState([]);
  const [loadingInitialIdeas, setLoadingInitialIdeas] = useState(false);
  const [loadingMainIdeas, setLoadingMainIdeas] = useState(false);

  const API_URL = process.env.NEXT_PUBLIC_API_URL;

  const [cookies, setCookie, removeCookie] = useCookies([COOKIE_NAME_ID, COOKIE_NAME_COUNTRY])

  const onPhotoSelect = (file) => {
    props.setPhoto(file);
  };


  useEffect(() => {
    if (!cookies[COOKIE_NAME_ID]) {
      // generate a semi random short id
      const randomId = Math.random().toString(36).substring(7)
      setCookie(COOKIE_NAME_ID, randomId)
    }
    if (!cookies[COOKIE_NAME_COUNTRY]) {
        // generate a semi random short id
          axios
              .get("https://ipapi.co/json/")
              .then((response) => {
                  let data = response.data;
                  setCookie(COOKIE_NAME_COUNTRY, data.country_code)
          }).catch((error) => {
              console.log(error);
          });
      }
  }, [cookies, setCookie])




  //trigger update to isdisabled when topicselected is true
  useEffect(() => {
    if (props.topicSelected) {
      setIsDisabled(true);
    }
  }, []);

  const textAreaRef = useRef(null);

  useEffect(() => {
    const textarea = textAreaRef.current;
    if (textarea) {
      textarea.addEventListener("input", () => autoExpand(textarea));
      return () => {
        // Cleanup: remove event listener when component is unmounted
        textarea.removeEventListener("input", () => autoExpand(textarea));
      };
    }
  }, []); // Empty dependency array: run effect once, similar to componentDidMount

  function autoExpand(textarea) {
    textarea.style.height = "auto"; // Reset height to auto before calculating the scroll height
    textarea.style.height = textarea.scrollHeight + "px";
  }

  //run autoExpand on text update
  useEffect(() => {
    autoExpand(textAreaRef.current);

    // console.log("topic modified", props.topic)
  }, [props.topic]);



  useEffect(() => {
    getSetRecentProducts();
  }, []);

  function getExamples() {
    //randomly select a book from the list of books
    const randomIndex = Math.floor(
      Math.random() * context.recentProducts.length,
    );
    return context.recentProducts[randomIndex];
  }

  function processTopic(topic) {
    topic = topic
      .trim()
      .replace("||", "")
      .replace(/\n\d+\. /, "")
      .replace(/Option \d+: /, "")
      .replace(/option \d+: /, "")
      .replace(/^\d+\.(?=\s|$)/, "")
      .replace(/\[/g, "")
      .replace(/\]/g, "");
    topic = topic.charAt(0).toUpperCase() + topic.slice(1).toLowerCase(); // Capitalize the first letter of the topic
    return topic;
  }

  function getDBTopics() {
    fetch("/api/telemetry/topics")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        //only return topics for data
        data = data.map((topic) => topic.topic);
        return data

      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
    return []
  }

  async function getSetRecentProducts() {
    if (context.isMainPage) {
      const { data, error } = await supabase_public.auth.getSession();
      const uuid = data?.session?.user.id || null;

      if (uuid) {
        setSignedIn(true);

        fetch(API_URL + `/topicGeneration`, {
          method: "POST",
          body: JSON.stringify({ uuid }),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            //process topic for each topic
            data.topics.forEach((topic, index) => {
              data.topics[index] = processTopic(topic);
            });
            setRecentProducts(data.topics);
          })
          .catch((error) => {
            //if this api call fails / returns error call get getdbtopics too
            setRecentProducts(getDBTopics())
          });
      } else {
        //if signed in fetch API_URL + /topicGeneration and set that to recent products
        //fetch to /api/topics and set that to recent products
        setRecentProducts(getDBTopics())
      }
    } else {
      setRecentProducts(context.recentProducts);
    }
  }

  function handleRecentProductClick(idea) {
    setExample(idea)
    posthog.capture('generated recent product clicked', {idea: idea, sessionId: props.sessionUUID})
  }

  function handleChange(event: any) {
    props.updateTopic(event.target.value);
    //setIsDisabled(false);
    //props.setTopicSelected(false)
  }

  function startSearch() {

    if (props.topic!="") {
      posthog.capture('recs generated', {sessionId: props.sessionUUID})
      setIsDisabled(true);
      let askedQuestions = [];

      ideas.forEach((idea) => {
        if (idea.options.some((option) => option.selected)) {
          idea.options.filter((option) => option.selected).forEach((selectedOption) => {
            askedQuestions.push([ idea.name + "?",  selectedOption.option ]);
          });
        }
      });
      props.setAskedQuestions([askedQuestions]);
      if (!props.topicSelected) {
        props.setTopicSelected(true);
        const currentPath = window.location.pathname.substring(1);
        window.history.pushState({}, '', `threads/${props.sessionUUID}?ref=${encodeURIComponent(currentPath)}`);
      }
    }
  }

  function agiBuy() {
    const randomItem = agiShoppingList[Math.floor(Math.random() * agiShoppingList.length)];
    props.updateTopic(randomItem);

    setIdeas([]);
    if (randomItem !== "") {
      posthog.capture('agi buy button', {sessionId: props.sessionUUID, topic: randomItem});
      posthog.capture('recs generated', {sessionId: props.sessionUUID, topic: randomItem});
      setIsDisabled(true);
      let askedQuestions = [];
      props.setAskedQuestions([askedQuestions]);

      if (!props.topicSelected) {
        props.setTopicSelected(true);
        const currentPath = window.location.pathname.substring(1);
        window.history.pushState({}, '', `threads/${props.sessionUUID}?ref=${encodeURIComponent(currentPath)}`);
      }
    }
  }

  function handleKeyDown(event: any) {
    // enter being pressed with no shift key
    if (event.key === "Enter" && !event.shiftKey) {
      startSearch();
    }
  }

  function handleClick(event: any) {
    startSearch();
  }


  function showExamples() {
    const example = getExamples();
    setExample(example);
  }

  function setExample(example) {
    props.updateTopic(example)
    setIdeas([])
    getSetIdeas(example)
  }


  async function getSetIdeas(topic) {
    setLoadingInitialIdeas(true);
    try {
      // Fetch ideas data and price data in parallel
      const [ideasData, priceData] = await Promise.all([
        getIdeas(topic, context.language, context.isMainPage),
        getPrice(topic, context.language, context.isMainPage)
      ]);

      const existingIdeas = ideas.filter((idea) => idea.options.some((option) => option.selected));

      const combinedData = [...(ideasData || []), ...(priceData || [])];
      if (combinedData.length > 0) {
        setIdeas(existingIdeas.concat(combinedData));
      }
    } catch (error) {
      console.error("Error fetching ideas and prices:", error);
    } finally {
      setLoadingInitialIdeas(false);
    }
  }

  //when keyinput stops after 200 ms call getIdeas
  useEffect(() => {
    let timer;
    const handleKeyUp = (event) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        if (props.topic.length > 0) {
          getSetIdeas(props.topic);
        }
      }, 350);
    };

    // Attach the event listener to the textAreaRef element
    const textArea = textAreaRef.current;
    if (textArea) {
      textArea.addEventListener('keyup', handleKeyUp);
    }

    return () => {
      // Ensure to remove the event listener from the same element
      if (textArea) {
        textArea.removeEventListener('keyup', handleKeyUp);
      }
      clearTimeout(timer);
    };
  }, [props.topic, context.isMainPage]);

  useEffect(() => {
    if (props.topic.length > 0) {
      setLoadingInitialIdeas(true);
    }
  }, [props.topic]);


  const paper_style = {
    padding: "5%",
    boxShadow: "0 4px 6px 0 hsla(210, 100%, 75%, 0.5)",
    borderRadius: "25px",
  };

  return (
    <>
      {!props.topicSelected && (
        <>
        <div className="mx-auto">
          {/* add a space size of 25% the height of the screen use tailwind */}
          <Space h="28vh" />
          {context.linkSiteSubHeader ? (
            <div className="p-4 text-2xl md:text-3xl">
              <a href={context.website}>{context.subheader}</a>
            </div>
          ) : (
            <div className="p-4 text-2xl md:text-3xl ">
              <div className="p-4 text-center text-3xl font-bold font-medium tracking-tight">
              {context.productInstruction}
              {/* {props.profile?.user_info?.name ? `Hi ${props.profile.user_info.name.split(' ')[0]},` : 'Hi,'} {context.productInstruction.toLowerCase()} */}
              {/* <TypeIt
                options={{
                  speed: 50,
                  afterComplete: function (instance) {
                    instance.destroy();
                  }
                }}
              >
                {props.profile?.user_info?.name ? `Hi ${props.profile.user_info.name.split(' ')[0]},` : 'Hi,'} {context.productInstruction.toLowerCase()}
              </TypeIt> */}
              </div>
            </div>
          )}

          {/* if context.id is giftem render locatioin input */}


          <div className="mx-auto flex w-full  justify-center ">
            <div className="flex w-full items-start font-sans ">
              <div className="relative w-full items-start sm:mx-auto">
                <div className="absolute flex h-full items-center justify-center">
                  <SearchIcon
                    className="pointer-events-none ml-4 select-none text-zinc-400"
                    size={18}
                  />
                </div>
                <input
                  // rows={1}
                  ref={textAreaRef}
                  value={props.topic}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  placeholder={props.photo ? "additional context" : context.productPlaceholder}
                  disabled={isDisabled}
                  className={clsx(
                    "h-full w-full select-none resize-none rounded-full border bg-zinc-100  pr-8 py-3 pl-12 text-zinc-800  outline-none transition-all duration-75 placeholder:text-zinc-400 focus:border-indigo-500 sm:min-w-[400px] lg:min-w-[600px]",
                    {},
                  )}
                  style={{
                    overflow: "visible",
                    wordWrap: "break-word",
                    whiteSpace: "pre-wrap",
                  }}
                />
                <div className="absolute right-0 inset-y-0 flex items-center pr-4">
                  <PhotoUploadComponent onPhotoSelect={onPhotoSelect} topic={props.topic} sessionUUID={props.sessionUUID}  />
                </div>
              </div>
            </div>
          </div>
          {
            props.customFilters && (
              <div className="h-auto w-full select-none  resize-none rounded-b-xl border-none pt-4 text-base text-zinc-800 outline-none transition-all duration-75 ">
              <HeaderCustomFilters
                customFilters={props.customFilters}
                setCustomFilters={props.setCustomFilters}
                />
            </div>
            )
          }

          {loadingInitialIdeas ? (
              <div className={clsx("h-auto w-full select-none resize-none rounded-b-xl border-none text-base text-zinc-800 outline-none transition-all duration-75", {"py-6": !props.customFilters})}>
                <div className="text-textMain mx-4 text-sm ml-6 mb-2 flex items-center">
                  <FaSortAmountDown className="mr-2" /> Generating filters...
                </div>
              <div className="flex items-center justify-start space-x-2">
                <div className="animate-pulse flex items-center justify-center space-x-2">
                  <SkeletonIdeaComponent />
                </div>
              </div>
            </div>
          ) : (
             ideas.length != 0 && (
              <div className={clsx("h-auto w-full select-none resize-none rounded-b-xl border-none text-base text-zinc-800 outline-none transition-all duration-75", {"py-6": !props.customFilters})}>
              <HeaderIdeas
                ideas={ideas}
                setIdeas={setIdeas}
                savedIdeas={savedIdeas}
                setSavedIdeas={setSavedIdeas}
                topic={props.topic} setTopic={props.updateTopic} />
            </div>

            )
          )}
          {props.photo && (
            <div className="flex justify-center mt-4">
              <div className="relative w-full max-w-xs md:max-w-sm lg:max-w-md overflow-hidden rounded-lg" style={{ paddingTop: '56.25%' }}>
                <img
                  src={URL.createObjectURL(props.photo)}
                  alt="Uploaded"
                  className="absolute top-0 left-0 w-full h-full object-cover rounded-lg"
                />
              </div>
            </div>
          )}

          <div className="mt-4 mb-6 flex flex-col items-center justify-center space-y-2">
            <div className="flex items-center justify-center space-x-2">
              <button
                className="hover-shadow flex w-40 select-none items-center justify-center rounded-full bg-indigo-700 py-2 text-sm text-white transition-all hover:bg-indigo-600 active:scale-95"
                onClick={handleClick}
              >
                Search
              </button>
              {context.id != "stonetooling" && (
                <button
                  className="w-40 select-none rounded-full border border-zinc-200 bg-transparent py-2 text-sm text-zinc-700 transition-all duration-75 hover:border-zinc-200 hover:bg-zinc-200 active:scale-95"
                  onClick={showExamples}
                >
                  Show an example
                </button>
              )}
            </div>
            <button
              className="w-40 select-none rounded-full border border-zinc-200 bg-transparent py-2 text-sm text-zinc-700 transition-all duration-75 hover:border-zinc-200 hover:bg-zinc-200 active:scale-95 flex items-center justify-center space-x-2"
              onClick={agiBuy}
            >
              <FaRobot size={20} className="inline" />
              <span className="inline">LET AGI BUY</span>
            </button>
          </div>

        </div>
                <div className="flex flex-wrap justify-center items-baseline">

                {recentProducts && recentProducts.slice(0, 4).map((idea, index) => (
                  <div key={index} className="flex items-center justify-start">
                    <button
                      className="w-40 h-full select-none rounded-xl m-4 border border-zinc-200 bg-transparent py-2 px-1 text-sm text-zinc-700 transition-all duration-75 hover:border-zinc-200 hover:bg-zinc-200 active:scale-95"
                      onClick={() => handleRecentProductClick(idea)}
                    >
                      {idea}
                    </button>
                  </div>
                ))}
              </div>
              </>
      )}
    </>
  );
}
