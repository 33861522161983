import React from 'react'
import { Skeleton } from "../../@/components/ui/skeleton"

const SkeletonIdeaCard = () => {
    return (
        <Skeleton className="rounded-full mt-2 px-6 py-2 text-[12px]  ml-4  cursor-pointer text-center flex items-center justify-center leading-4 bg-zinc-200 text-textGray " />    
    )    
}

const SkeletonIdeaComponent = () => {
    return (
        <div className="mb-6">
            <div className="flex flex-row pl-2">
                <SkeletonIdeaCard />
                <SkeletonIdeaCard />
                <SkeletonIdeaCard />
            </div>
        </div>
    )
}

export default SkeletonIdeaComponent;
