import { Button } from "../../../@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../@/components/ui/dialog"
import { Input } from "../../../@/components/ui/input"
import { useState } from "react"
import { IoCloseSharp } from "react-icons/io5"
import { ProductCardModal } from "./ProductCardModal"
import { ScrollArea } from "../../../@/components/ui/scroll-area"
import { Loader } from '@mantine/core';

export function MoreProductsModalContent(props) {
  return (
    <>
    <div className="fixed h-screen w-screen left-0 top-0 flex justify-center items-center overflow-y-scroll" style={{zIndex: 9999, backdropFilter: 'blur(5px)', background: 'rgba(0, 0, 0, 0.1)'}} hidden={!props.open} onClick={props.toggle}>

          <div className="bg-white w-full lg:w-1/2 xl:w-1/2 rounded-xl overflow-hidden" style={{"height": "95.666666%"}} onClick={(e) => e.stopPropagation()}>
              <div className="top-0 right-0 flex justify-end m-2">
                  <button className="flex p-4" onClick={() => {props.toggle()}}>
                    <IoCloseSharp className="h-6 w-6 text-gray-500" />
                  </button>
              </div>

              <div className="h-full overflow-auto grid grid-cols-1 gap-1">
              {/* LOOK HERE monetize link dymaically when preview clikced inside modal */}
              {props.products === null ? (
                <div className="flex justify-center items-center h-full">
                  <Loader color="#4338CA" />
                </div>
              ) : props.products.length === 0 ? (
                <div className="flex justify-center items-center h-full">
                  <p>Couldn't find anything</p>
                </div>
              ) : (
                props.products.map((product, index) => (
                  <div key={product.id} className="">
                  <ProductCardModal
                    product={product}
                    image={product.imageUrl}
                    price={product.price}
                    title={product.title}
                    link={product.detailPageURL}
                    detailPageURL={product.detailPageURL}
                    rating={product.rating}
                    sku={product.sku}
                    inlineProduct={true}
                    bestSeller={product.best_seller}
                    amazonsChoice={product.is_amazons_choice}
                    reviews_count={product.reviews_count}
                    source={product.source}
                    index={index}
                    handleProductSpecificSearch={props.handleProductSpecificSearch}
                    aiSummary={product.aiSummary}
                    setCheckoutProduct={props.setCheckoutProduct}
                    forceCheckout={props.forceCheckout}
                    session={props.session}
                    className='px-2'
                    redditRefs={props.redditRefs}
                    videos={props.videos}
                    gl={props.gl}
                    searchQuery={props.searchQuery}
                    askedQuestions={props.askedQuestions}
                  />
                  </div>
                ))
              )}
              <div className="h-20"></div>
              </div>
          </div>
    </div>
    </>
  )
}
