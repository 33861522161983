import { PageContext } from '../../contexts/pageContext';


export const updateProductLinkFallback = (product, AFFILIATE_LINK) => {
  if (!product || !product.detailPageURL) {
    return;
  }

  if (AFFILIATE_LINK !== '') {
    if (product.detailPageURL.includes('?')) {
      product.taggedURL = product.detailPageURL + `&${AFFILIATE_LINK}&utm_source=claros`;
    } else {
      product.taggedURL = product.detailPageURL + `?${AFFILIATE_LINK}&utm_source=claros`;
    }
  } else {
    if (product.detailPageURL.includes('?')) {
      product.taggedURL = product.detailPageURL + '&utm_source=claros';
    } else {
      product.taggedURL = product.detailPageURL + '?utm_source=claros';
    }
  }
};
