import yaml from "js-yaml";
import axios from 'axios'

const API_URL = process.env.NEXT_PUBLIC_API_URL;

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export async function getMoreIdeas(input, ideas) {

  try {
    const questionResponse = await axios.post("/api/rsc/generateMore", {
      topic: input,
      ideas: ideas,
      skip: false,
    }, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    const considerationData = questionResponse.data;
    const considerations = considerationData && considerationData.hasOwnProperty('ideas') ? considerationData.ideas : null;
    let parsedConsiderations = [];

    for (const key in considerations) {
      const consideration = considerations[key];
      const name = capitalizeFirstLetter(consideration.name);
      const reason = consideration.reason;
      const options = consideration.options.map((option) => {
        return { selected: false, option: option };
      });
      parsedConsiderations.push({ name, reason, options });
    }
    return parsedConsiderations;
  } catch (error) {
    console.log("error ", error)
  }
}


let cancelToken;

export async function getIdeas(input, language, isMainPage) {
  // Cancel the previous request
  if (cancelToken) {
    cancelToken.cancel();
  }

  // Create a new CancelToken
  cancelToken = axios.CancelToken.source();

  try {
    const questionResponse = await axios.post("/api/rsc/generateInitial", {
      topic: input,
      language: language,
      skip: false,
      isB2C: isMainPage,
    }, {
      cancelToken: cancelToken.token,
      headers: {
        "Content-Type": "application/json",
      },
    });

    const considerationData = questionResponse.data;
    const considerations = considerationData && considerationData.hasOwnProperty('ideas') ? considerationData.ideas : null;
    let parsedConsiderations = [];

    for (const key in considerations) {
      const consideration = considerations[key];
      const name = capitalizeFirstLetter(consideration.name);
      const reason = consideration.reason;
      const options = consideration.options.map((option) => {
        return { selected: false, option: option };
      });
      parsedConsiderations.push({ name, reason, options });
    }
    return parsedConsiderations;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled', error.message);
    } else {
      // handle error
      throw error;
    }
  }
}


export async function getPrice(input, language, isMainPage) {

  try {
    const questionResponse = await axios.post("/api/rsc/generatePrice", {
      topic: input,
      language: language,
      skip: false,
      isB2C: isMainPage,
    }, {
      cancelToken: cancelToken.token,
      headers: {
        "Content-Type": "application/json",
      },
    });

    const consideration= questionResponse.data;
    let parsedConsiderations = [];

    const name = "Price range";
    const reason = consideration.thinking;
    const options = consideration.options.map((option) => {
      return { selected: false, option: option };
    });
    parsedConsiderations.push({ name, reason, options });


    return parsedConsiderations;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled', error.message);
    } else {
      // handle error
      throw error;
    }
  }
}
