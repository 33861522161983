import { useState } from "react";
import {
  createStyles,
  Header,
  Container,
  Group,
  Burger,
  Title,
  Text,
  Paper,
  Transition,
  Image,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { HeaderInputMin } from "./HeaderInputMin";
import { PageContext } from "../../contexts/pageContext";
import { useContext } from "react";
import { AwesomeButton } from "react-awesome-button";
import TopBanner from "../TopBanner";


// style={{color:inherit ; text-decoration:none}}
export function HeaderResponsive(props: any) {
  const context = useContext(PageContext);

  return (
    <div
      className={
        context.isMainPage
          ? "bg-bgwhite inline-block flex h-full flex-col items-center align-middle px-2 z-0"
          : " align-middle "
      }
    >
      {context.id != "stonetooling" && context.id != "oxford" && (
        <>
          {/* <TopBanner /> */}
          {context.isMainPage ? (
            <>
              {/* <div className="flex items-center">
                <a href="/landingpage" className="flex items-center">
                    <img src="../../logo-circle.png" className="h-16 sm:h-16 mr-3 " alt="Claros Logo" />
                    <span className="self-center text-4xl font-semibold whitespace-nowrap  text-bluelogo ">Claros</span>
                </a>
            </div> */}
            </>
          ) : context.id != "stonetooling" ? (
            <div
              className={` flex items-center justify-between pt-4 `}
              style={{ zIndex: 1 }}
            >
              <div className="flex items-center">
                <a href="/" className="flex items-center p-4">
                  <img
                    src="/logo-circle.png"
                    className="mr-3 h-10 sm:h-10 "
                    alt="Claros Logo"
                  />
                  <span className="text-bluelogo self-center whitespace-nowrap text-4xl  font-semibold ">
                    Claros
                  </span>
                </a>
              </div>
            </div>
          ) : (
            context.logoUrl != "" && (
              <a href={props.website}>
                <Image
                  src={props.logo}
                  fit="contain"
                  radius="lg"
                  width={context.logoWidth}
                  height={context.logoHeight}
                  alt="logo"
                />
              </a>
            )
          )}
        </>
      )}

      <HeaderInputMin
        updateTopic={props.updateTopic}
        topic={props.topic}
        topicSelected={props.topicSelected}
        setTopicSelected={props.setTopicSelected}
        updateLocation={props.updateLocation}
        location={props.location}
        setLocationSelected={props.setLocationSelected}
        coords={props.coords}
        setCoords={props.setCoords}
        sessionUUID={props.sessionUUID}
        askedQuestions={props.askedQuestions}
        setAskedQuestions={props.setAskedQuestions}
        setPhoto={props.setPhoto}
        photo={props.photo}
        customFilters={props.customFilters}
        setCustomFilters={props.setCustomFilters}
      />
    </div>
  );
}
