import {
  Space,
  Paper,
  Group,
  Button,
  Title,
  Text,
  TextInput,
  ActionIcon,
  Container,
  Grid,
} from "@mantine/core";
import { TrendingUp } from "tabler-icons-react";
import React, { useState, useContext } from "react";
import { PageContext } from "../../contexts/pageContext";
import { AwesomeButton } from "react-awesome-button";
import { BetterButton } from "../utils/Buttons";
import { LocationInput } from "../LocationInput";
import { useEffect, useRef } from "react";
import "react-awesome-button/dist/styles.css";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../@/components/ui/tooltip"
import { HeaderIdeaToolTip } from "./HeaderIdeaToolTip";
import clsx from 'clsx';
import { Loader } from '@mantine/core';
const API_URL = process.env.NEXT_PUBLIC_API_URL;

export function HeaderCustomFilters({ customFilters, setCustomFilters }) {
  const handleSelect = (index) => {
    const updatedFilters = customFilters.map((filter, idx) => {
      if (idx === index) {
        return { ...filter, selected: !filter.selected };
      }
      return filter;
    });
    setCustomFilters(updatedFilters);
  };

  return (
    <>
      {customFilters && customFilters.length > 0 && (
        <>
        <div className="text-textMain text-sm ml-4 mb-2 flex items-center">
            Custom Filters
        </div>
        <div className="mx-auto flex flex-row flex-wrap justify-start">
          {customFilters.map((filter, index) => (
            <div key={index} className="mb-6">
              <div className="text-textMain mr-1 text-sm flex items-center">

              <div className={clsx("rounded-full mt-2 px-3 py-1 text-[13px] md:text-[12px]  ml-4  cursor-pointer text-center flex items-center justify-center leading-4 active:scale-95",
              {"bg-indigo-500 text-white hover:bg-indigo-400": filter.selected},
              {"bg-cardBg text-textGray hover:bg-zinc-200 ": !filter.selected})}
              onClick={() => handleSelect(index)}>
                {/* {loading ? <Loader variant="dots" size={20} className='m-2' color='#3358d4' /> : (data.option)} */}
                {filter.name}
              </div>

              </div>
            </div>
          ))}
        </div>
        </>
      )}
    </>
  );
}
