import React, { useContext, useRef, useState } from 'react';
import { Send, Refresh, Share, Clipboard } from 'tabler-icons-react';
import { Space, Group, ActionIcon, } from '@mantine/core';
import { PageContext } from '../../contexts/pageContext'
import { FaRobot, FaForward, FaReddit } from "react-icons/fa";
import posthog from 'posthog-js';
import parseMarkdown from '../utils/MarkdowntoHTML';
import Citation from '../sources/Citation';
import { QuestionInset } from './QuestionInset';
import { useDisclosure } from '@mantine/hooks';
import { PiFadersHorizontal } from "react-icons/pi";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../../@/components/ui/collapsible"
import { GoChevronDown, GoChevronUp } from "react-icons/go";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../@/components/ui/accordion"
import { BsFilter } from 'react-icons/bs';

export const FollowUpQuestionCard = ({ qapair, topic, questionList, handleSubmit, setAskedQuestions, askedQuestions }) => {
  const [message, setMessage] = React.useState("");
  const [opened, { toggle }] = useDisclosure(false);
  const [selectedQuestion, setSelectedQuestion] = React.useState(null);
  const [answers, setAnswers] = useState({});

  function handleAnswerChange(index, answer) {
    // console.log("Setting question and answer", index, answer)
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [index]: answer
    }));
  }

  function handleSubmitAll() {
    let newQapair = [...qapair];

    const questionsAnswers = questionList.reduce((acc, q, idx) => {
      if (answers[idx]) {
        acc.push([q.question, answers[idx]]);
      }
      return acc;
    }, []);
    let allAskedQuestions = [...askedQuestions, questionsAnswers]
    setAskedQuestions( allAskedQuestions); // Append the new questionsAnswers to the array of already asked questions
    // Submit the answers along with other necessary data
    let query = `Here's some more information on what exactly I'm looking for: ${questionsAnswers.map(qa => `${qa[0]} ${qa[1]}`).join(', ')}`

    posthog.capture('follow-up question submitted', { query: query, questionsAnswers: questionsAnswers, topic: topic, qapair: qapair });
    handleSubmit(query, newQapair, topic, true, allAskedQuestions);
    setAnswers({}); // Reset answers state

  }

  return (
    <div className="rounded-xl overflow-hidden w-full">
        <div className='flex items-center w-3/12 w-full justify-start text-xl pt-12 pb-4 tracking-normal'>
          <BsFilter className='mr-2' size={30} /> Filters
        </div>
      <div className="mb-4 w-full ">
        {questionList && questionList.map((q, idx) => (
          <>
                <span className="font-sans text-sm text-start cursor-pointer tracking-normal">
                  {q.question}
                </span>
              <QuestionInset
                questionIndex={idx} // Pass the index as a prop
                questionObj={q}
                answer={answers[idx] || ''} // Use the index for accessing answers
                onAnswerChange={(index, answer) => handleAnswerChange(index, answer)}
                handleSubmitAll={handleSubmitAll}
              />
          </>
        ))}
      </div>
      <div className="flex justify-end">
        <button onClick={handleSubmitAll} className="bg-indigo-600 hover:bg-indigo-500 active:scale-95 text-white py-2 px-4 mr-2 rounded-full">
          Filter
        </button>
      </div>
    </div>
  );
};

