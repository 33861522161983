import { Text} from '@mantine/core';
import { useContext} from 'react';
import { PageContext } from '../../contexts/pageContext';
import React, { useState, useEffect, useRef } from 'react';
import { IoMdThumbsDown } from "react-icons/io";
import { IoIosShareAlt } from "react-icons/io";
import { IoReload } from "react-icons/io5";
import { FaFileSignature, FaCheckCircle } from "react-icons/fa";
import { Center, Loader } from '@mantine/core';
import { TbReload } from "react-icons/tb";
import posthog from 'posthog-js';
import parseMarkdown from '../utils/MarkdowntoHTML';
import getSymbolFromCurrency from 'currency-symbol-map'
import { ConfirmCheckout } from '../checkout/ConfirmCheckout';

export function ChatMessage(props) {
  const context = useContext(PageContext)
  const isBot = props.isBot;

  const link = `${window.location.href}/threads/${props.sessionId}#${props.idx}`;


  const [copied, setCopied] = useState(false);
  const [message, setMessage] = React.useState("");
  const [showCheckoutConfirm, setShowCheckoutConfirm] = React.useState(true);

  useEffect(() => {
    const [message, productDescriptionMap] = parseMarkdown(props.message, "sm", props.redditRefs, props.products)
    setMessage(message)
    props.setProductDescriptionMap(productDescriptionMap)

    // window.addEventListener('message', handleIframeMessage);

    // return () => {
    //   window.removeEventListener('message', handleIframeMessage);
    // };

  }, [props.message])

//   const handleIframeMessage = (event) => {
//     //posts stripe result back to us
//     if (event.data.checkout_token) {
//         // console.log("updated checkout", event.data)
//         props.setUpdatedCheckout(event.data);
//         //alwasy close the iframe after submit data
//         props.setCheckout(null)
//     }

//   };


  const handleCopy = async () => {
        await navigator.clipboard.writeText(link);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
  }

  if (isBot) {
    return (
        <div className="relative transform transition duration-300 ease-in-out rounded-xl text-gray-700 pt-4 px-2 w-full" id={props.idx}>

            <div className="text-sm md:text-base tracking-normal text-textMain prose prose-zinc">
                <div dangerouslySetInnerHTML={{ __html: message}} />
            </div>

            {copied &&
            <div className=" relative right-2 p-2 bg-white text-gray-500 text-xs rounded-xl border-1 border-color-bluelogo">
            Copied link!
            </div>
            }


            <button onClick={handleCopy} className="hover:bg-gray-100 rounded-xl cursor-point active:scale-95 origin-center whitespace-nowrap inline-flex text-sm px-sm font-medium hover:text-bluelogo p-2 text-gray-400">
                <IoIosShareAlt size={20}/>
                <div className="text-align-center relative ml-[2px]">Share</div>
            </button>

            <button onClick={props.setFeedbackOpen} className="float-right hover:bg-gray-100 rounded-xl cursor-point active:scale-95 origin-center whitespace-nowrap inline-flex text-sm px-sm font-medium hover:text-bluelogo p-2 text-zinc-400">
                <IoMdThumbsDown size={20}/>
            </button>

        </div>
    );
   } else {
        return (
    <div className="transform transition duration-300 ease-in-out rounded-xl text-gray-700 text-end"  >
        <div className="">

            <div className=" p-2 text-lg md:text-xl  leading-8" style={{whiteSpace: 'pre-wrap'}}>
                {props.message}
            </div>
        </div>
    </div>

)

   }
}
