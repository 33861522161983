import { MoreProductsModalContent} from "./MoreProductsModalContent"
import { useState } from "react"
import axios from "axios"

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../@/components/ui/dialog"
import posthog from "posthog-js"
const API_URL = process.env.NEXT_PUBLIC_API_URL;
export function CheaperProductsModal(props) {
  const [open, setOpen] = useState(false)
  const [cheaperProducts, setCheaperProducts] = useState(null)

  const fetchCheaperProducts = async () => {
    try {
      const response = await axios.post(`${API_URL}/product/cheaper`, {
        product: props.product,
        gl: props.gl
      })
      setCheaperProducts(response.data)
    } catch (error) {
      console.error("Error fetching cheaper products:", error)
    }
  }

  const handleOpen = () => {
    console.log("click")
    setOpen(true)
    fetchCheaperProducts()
  }

  return (
        <div className="flex items-start min-h-full ">
          <div className="hover:bg-zinc-200 cursor-pointer flex text-zinc-600 items-center text-sm bg-zinc-100 rounded-xl px-2 py-1 my-2" onClick={handleOpen}>
            See other deals
          </div>
          <MoreProductsModalContent
          open={open}
          toggle={() => {setOpen(!open); posthog.capture('deals button clicked', {})}}
          products={cheaperProducts}
          setCheckoutProduct={props.setCheckoutProduct}
          forceCheckout={props.forceCheckout}
          session={props.session}
          className='px-2'
          redditRefs={props.redditRefs}
          videos={props.videos}
          gl={props.gl}
          searchQuery={props.searchQuery}
          askedQuestions={props.askedQuestions}
          handleProductSpecificSearch={props.handleProductSpecificSearch}
          />
        </div>
  )
}
