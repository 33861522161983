import React from 'react'
import { FaExternalLinkAlt } from "react-icons/fa";
import posthog from 'posthog-js';


export const ProcessSectionCard = ({index, data}) => {
    const url = data.hasOwnProperty('detailPageURL') ? data.detailPageURL : (data.hasOwnProperty('link') ? data.link : (data.hasOwnProperty('url') ? data.url : null));
    const cardClass = url ? "cursor-pointer" : "";
    const onClick = url ? () => {
        window.open(url, "_blank");
        posthog.capture('Process Section Card click', { url });
    } : null;


    return (
        <div key={index} className={`px-3 py-2 text-xs text-center text-textGray bg-cardBg rounded-xl mx-2  ${cardClass} tracking-normal justify-between flex flex-col`} onClick={onClick}>
            <div className='flex items-center'>
                <div className='max-w-40 text-start break-words'>
                    {data.title}
                </div>


            </div>
            {url &&
            <div className='pt-2'>
                <div className='flex'>
                    <img src={`https://www.google.com/s2/favicons?domain=${url}&sz=64`} alt="favicon" className='w-4 h-4 mr-2' />
                    {
                    url ? (() => {
                        try {
                            const parsedUrl = new URL(url, window.location.origin);
                            return parsedUrl.hostname.replace(/^(?:https?:\/\/)?(?:www\.)?/, '');
                        } catch (error) {
                            console.error("Invalid URL:", url, error);
                            return "";
                        }
                        })() : ''
                    }
                </div>
            </div>
            }
        </div>
    )
}

