import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../@/components/ui/tooltip"
import { PiInfo } from "react-icons/pi";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
  } from "../../@/components/ui/popover"


export function HeaderIdeaToolTip({reason}) {

    return (
    <>
    <div className="block lg:hidden">
        <Popover>
            <PopoverTrigger>
                    <PiInfo className="ml-1 text-textGray" />
            </PopoverTrigger>
            <PopoverContent className="bg-white text-sans rounded-xl border">
              <p className="p-4 bg-white w-80">{reason}</p>
            </PopoverContent>
        </Popover>
    </div>
    <div className="hidden lg:block">
        <TooltipProvider>
          <Tooltip delayDuration={0}>
            <TooltipTrigger>
                <PiInfo className="ml-1 text-textGray" />
            </TooltipTrigger>
            <TooltipContent className="bg-white text-sans rounded-xl border">
              <p className="p-4 bg-white w-96">{reason}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
    </div>
    </>
    )
}