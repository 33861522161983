export async function generateChat(query, history, topic, recs, web_recs, numberOfInlineProducts, searchQuery, showRecs, askedQuestionsPassed, sources, videoRecs, object, context, user_cookie, profile, setQapair, setLoaderVisible, setDesc) {

    let person = '';
    if (videoRecs.length > 0 && videoRecs[videoRecs.length - 1].length > 0) {
        person = videoRecs[videoRecs.length - 1][0].author;
        console.log("person", person)
    }

    const chattestresponse = await fetch(context.recsUrl , {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({
            query: query,
            history: history,
            topic: topic,
            recs: recs,
            web_recs: web_recs,
            user: user_cookie,
            language: context.language,
            skip: showRecs,
            numberOfInlineProducts: numberOfInlineProducts,
            searchQuery: searchQuery,
            isB2C: context.isMainPage,
            profile: profile,
            sources: sources[sources.length - 1],
            videos: videoRecs[videoRecs.length -1],
            person: person,
            askedQuestions: askedQuestionsPassed,
            object: object
        }),
    })

    // console.log("sources", sources[sources.length - 1])

    const data = chattestresponse.body

    console.log(data)

    if (!data) {
        return
    }
    const reader = data.getReader()
    const decoder = new TextDecoder()
    let done = false
    let lastMessage = ''
    let pros = []
    let cons = []

    while (!done) {
        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        if (value) {
            const chunkValue = decoder.decode(value, { stream: true }).trim();
            // Split the chunkValue by newline to get individual JSON strings
            const jsonStrings = chunkValue.split('\n');

            jsonStrings.forEach(jsonString => {
                try {
                    if (jsonString) { // Make sure the jsonString is not empty
                        const jsonData = JSON.parse(jsonString);
                        if (jsonData.type === 'description') {
                            // Handle product description
                            setDesc(jsonData.data);
                        } else if (jsonData.type === 'result') {
                            // Accumulate chat messages as they stream in
                            // let chatMessage = jsonData.data.reason + "\n\n" + jsonData.data.user_reviews
                            let chatMessage = jsonData.data
                            if (jsonData.data.pros) {
                                pros = jsonData.data.pros
                            }
                            if (jsonData.data.cons) {
                                cons = jsonData.data.cons
                            }

                            lastMessage += chatMessage;
                            // Append accumulated chat message to the UI
                            setQapair([...history, [query, lastMessage]]);
                            setLoaderVisible(false);
                        }
                    }
                } catch (error) {
                    console.error('Error parsing JSON:', error, 'from jsonString:', jsonString);
                }
            });
        }
    }

    let qapairnew2 = [...history, [query, lastMessage]]

    //generate questions after chat so if it fails chat will still stream first

    return {
        lastMessage: lastMessage,
        newHistory: qapairnew2,
        newOptions: null,
        pros,
        cons
    }
}