import React, { useState, useEffect, useRef } from 'react';
import { FaFileSignature, FaCheckCircle } from "react-icons/fa";
import getSymbolFromCurrency from 'currency-symbol-map'
import { Center, Loader } from '@mantine/core';

export function ConfirmCheckout(props) {
    return (

        <>
        <div className="mt-8 sm:mt-16 bg-white shadow-md rounded-lg p-6 mx-auto max-w-4xl font-gilroy-regular text-textMain">
                <h1 className="text-base border-b pb-4 flex items-center">
                    <FaFileSignature className="mr-2" /> Checkout Summary
                </h1>
                <div className="mt-4">
                    <p className="text-base">Merchant: <span>{props.updatedCheckout?.merchant}</span></p>
                    <p className="text-base">Subtotal: <span> {getSymbolFromCurrency(props.updatedCheckout?.currency)}{(props.updatedCheckout?.subtotal_amount / 100).toFixed(2).replace(/\.00$/, '')} </span></p>
                    <p className="text-base">Tax: <span> {getSymbolFromCurrency(props.updatedCheckout?.currency)}{(props.updatedCheckout?.tax_amount / 100).toFixed(2).replace(/\.00$/, '')} </span></p>
                    <p className="text-base">Total: <span> {getSymbolFromCurrency(props.updatedCheckout?.currency)}{(props.updatedCheckout?.total_amount / 100).toFixed(2).replace(/\.00$/, '')} </span></p>
                    <p className="text-base">Shipping Address: <span>{props.updatedCheckout?.shipping_info?.address?.address1}</span></p>
                    <p className="text-base">Email: <span>{props.updatedCheckout?.shipping_info?.email}</span></p>
                </div>

                {/* Display other checkout details as needed */}
                {props.confirmCheckout && !props.checkoutSuccess ? (
                    <button className="mt-6 bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded">
                        Confirm
                        <Loader color="indigo" />
                    </button>
                ) : props.checkoutSuccess ? (
                    <div className="mt-4 text-green-600 flex items-center">
                        <span>Thank you!</span> <FaCheckCircle className='ml-1' />
                    </div>
                ) : (
                    <button onClick={() => { props.setConfirmCheckout(true)}} className="mt-6 bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded">
                        Confirm
                    </button>
                )}
                </div>
                </>

    )

}