
const API_URL = process.env.NEXT_PUBLIC_API_URL
const COOKIE_NAME_ID = 'product-help-chat-gpt3'
const COOKIE_NAME_COUNTRY = 'product-help-chat-gpt3-country'


export async function followUpQuestions(query, searchQuery, history, topic, object, askedQuestionsPassed, language, setQuestionsList) {
    try {
        const questionResponse = await fetch(API_URL + 'nextQuestionTest', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query: query,
                searchQuery: searchQuery,
                history: history,
                topic: topic,
                object: object,
                language: language,
                askedQuestions: askedQuestionsPassed
            }),
        });
        if (!questionResponse.ok) {
            throw new Error(`HTTP error! status: ${questionResponse.status}`);
        }
        const questions = await questionResponse.json();
        setQuestionsList(questions)
        return questions;
        // const questionReader = questionResponse.body.getReader();

        // let questionDone = false;
        // let questions = []
        // const decoder = new TextDecoder();
        // while (!questionDone) {
        //     const { value, done: doneReading } = await questionReader.read();
        //     questionDone = doneReading;
        //     if (value) {
        //         const chunkValue = decoder.decode(value, { stream: true }).trim();

        //         const jsonStrings = chunkValue.split('\n');
        //         jsonStrings.forEach(jsonString => {
        //             try {
        //                 if (jsonString) { // Make sure the jsonString is not empty
        //                     const jsonData = JSON.parse(jsonString);
        //                     questions.push(jsonData)
        //                 }
        //             } catch (error) {
        //                 console.error('Error parsing JSON:', error, 'from jsonString:', jsonString);
        //             }
        //         });
        //     }
        // }
        // await setQuestionsList(questions)
    } catch (error) {
        console.error('Error fetching follow-up questions:', error);
        return []
    }
}