import React, { useState } from 'react';
import clsx from 'clsx';
import { IdeaCard } from './IdeaCard';
import { HeaderIdeaToolTip } from './HeaderIdeaToolTip';
import { MoreIdeasCard } from './MoreIdeasCard';

export function HeaderIdeas({ ideas, setIdeas, savedIdeas, setSavedIdeas, topic, setTopic }) {
  const [customOptions, setCustomOptions] = useState(ideas.reduce((acc, idea) => ({ ...acc, [idea.name]: '' }), {}));
  const [activeCustomOptions, setActiveCustomOptions] = useState(ideas.reduce((acc, idea) => ({ ...acc, [idea.name]: false }), {}));
  const [submittedCustomOptions, setSubmittedCustomOptions] = useState({});

  const handleCustomOptionChange = (name, value) => {
    setCustomOptions((prev) => ({ ...prev, [name]: value }));
  };

  const submitCustomOption = (name) => {
    const customOption = customOptions[name];
    if (customOption.trim() !== '') {
      const newOption = { option: customOption, selected: true };
      const updatedIdeas = ideas.map((idea) =>
        idea.name === name ? { ...idea, options: [...idea.options, newOption] } : idea
      );
      setIdeas(updatedIdeas);
      setCustomOptions((prev) => ({ ...prev, [name]: '' }));
      setActiveCustomOptions((prev) => ({ ...prev, [name]: false }));
      setSubmittedCustomOptions((prev) => ({ ...prev, [name]: true }));
    }
  };

  return (
    <>
      {ideas && (
        <div className="mx-auto">
          {ideas.map((item, index) => (
            <div key={index} className="mb-6">
              <div className="text-textMain mx-4 text-sm flex items-center">
                {item.name}
                <HeaderIdeaToolTip reason={item.reason} />
              </div>
              <div className="flex flex-row flex-wrap">
                {item.options.map((option, idx) => (
                  <IdeaCard
                    key={idx}
                    name={item.name}
                    data={option}
                    topic={topic}
                    setTopic={setTopic}
                    ideas={ideas}
                    setIdeas={setIdeas}
                  />
                ))}
                {activeCustomOptions[item.name] ? (
                  <input
                    className={clsx(
                      "rounded-full mt-2 px-3 py-1 text-[13px] md:text-[12px] ml-4 cursor-pointer text-center flex items-center justify-center leading-4 active:scale-95",
                      "bg-cardBg text-textGray hover:bg-zinc-200"
                    )}
                    value={customOptions[item.name]}
                    onChange={(e) => handleCustomOptionChange(item.name, e.target.value)}
                    onBlur={() => submitCustomOption(item.name)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        submitCustomOption(item.name);
                      }
                    }}
                    onFocus={() => setActiveCustomOptions((prev) => ({ ...prev, [item.name]: true }))}
                    placeholder=""
                    autoFocus
                  />
                ) : (
                  <div
                    className={clsx(
                      "rounded-full mt-2 px-3 py-1 text-[13px] md:text-[12px] ml-4 cursor-pointer text-center flex items-center justify-center leading-4 active:scale-95",
                      "bg-cardBg text-textGray hover:bg-zinc-200"
                    )}
                    onClick={() => setActiveCustomOptions((prev) => ({ ...prev, [item.name]: true }))}
                  >
                    +
                  </div>
                )}
              </div>
            </div>
          ))}
          {ideas && <MoreIdeasCard topic={topic} ideas={ideas} setIdeas={setIdeas} />}
        </div>
      )}
    </>
  );
}
