import { FaCheck } from 'react-icons/fa'
import { FaX } from 'react-icons/fa6' 

export const ProductProCon = ({pros, cons}) => {
    return (
        <div className="grid grid-cols-1 gap-2 px-4">
            {pros && pros.map((pro, index) => (
                <div key={index} className="mt-4 text-sm text-textMain tracking-normal">
                    <div className="col-start-1">
                        <FaCheck className="inline mr-2 text-green-400 " />
                        <div className="inline font-medium">{pro.title}</div>
                    </div>
                    <div className="col-start-2 mt-2 text-xs ">{pro.description}</div>
                </div>
            ))}
            {cons && cons.map((con, index) => (
                <div key={index} className="mt-4 text-sm text-textMain tracking-normal">
                    <div className="col-start-1">
                        <FaX className="inline mr-2 text-red-400" />
                        <div className="inline font-">{con.title}</div>
                    </div>
                    <div className="col-start-2 mt-2 text-xs ">{con.description}</div>
                </div>
            ))}
        </div>
    )
}
